body,
html {
  --ag-row-hover-color: #ffe8cd;
  --ag-selected-row-background-color: #fbc88f;
  --ag-checkbox-checked-color: #ee7f01;
  --ag-range-selection-border-color: #ee7f01;
  --header-h: 60px;
}

body,
html,
#root {
  font-size: 1rem !important;
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url("password.ttf");
}

@font-face {
  font-family: "Marianne";
  src: url(./fonts/Marianne-Regular.otf) format("otf");
}

input.password {
  font-family: "password";
  font-size: 14px;
}

.backdrop-loading {
  z-index: 1301 !important;
}

.header-main {
  z-index: 1202 !important;
  height: var(--header-h);
}

.header-main h1 {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.header-main .MuiToolbar-root {
  padding-right: 10px !important;
}

.header-main .MuiToolbar-root img {
  height: 40px;
}

.header-main .MuiAutocomplete-root .MuiInputBase-root {
  background-color: #fff;
}

.header-main .col-btn-user {
  border-left: 1px solid #000;
}

.content-main {
  padding-top: var(--header-h);
  width: 100%;
}

.drawer-main {
  width: 240px;
}

.drawer-main > .MuiPaper-root {
  width: 240px;
}

.fab-bottom-right {
  position: fixed !important;
  right: 16px;
  bottom: 16px;
}

div[role="tooltip"] {
  z-index: 1200;
}

.fab-bottom-right {
  position: fixed !important;
  right: 16px;
  bottom: 16px;
  z-index: 1203;
}

.alert-dense {
  padding: 0 10px !important;
}

.chip-visit-value .MuiChip-label {
  line-height: 1.1;
}

.card-image {
  padding-top: 40px;
  position: relative;
}

.card-image p {
  max-height: 40px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 3px 6px;
  padding-bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
}

.card-image p::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 6px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.card-image:hover p {
  max-height: none;
}

.card-image:hover p::after {
  display: none;
}

.card-image img {
  display: block;
  width: 400px;
  height: 400px;
  object-fit: contain;
}

.has-overlay {
  position: relative;
}

.has-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  z-index: 1000;
}

mark {
  font-weight: bold;
  text-shadow: -1px 1px 1px white;
  padding: 0 2px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.form-control-label-block {
  margin-left: 0 !important;
}

.form-control-label-block .MuiFormControlLabel-label {
  white-space: nowrap;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.7);
}

.MuiFormControlLabel-root.Mui-disabled .MuiFormControlLabel-label {
  color: #000;
}

.MuiFormControlLabel-root.Mui-disabled .Mui-checked {
  color: rgba(238, 127, 1, 0.4);
}

.MuiTextField-root.readonly .MuiInputBase-root {
  color: #000000cc !important;
}

.MuiTextField-root.readonly fieldset {
  border-color: transparent !important;
}

.form-report-infos .position-map {
  width: 100%;
  min-height: 300px;
}

.form-report-infos .col-visit-coords .chip-visit-value {
  margin-bottom: 0 !important;
}

.container-declaration .row-question:not(:last-child) {
  margin-bottom: 30px;
}

.container-declaration .col-title {
  font-weight: bold;
  padding-right: 0;
}

.print-friendly tr,
.print-friendly td {
  page-break-inside: avoid;
}

.print-new-page {
  page-break-before: always;
}

.container-pdf {
  background: #fff;
}

.files {
  max-width: 300px;
  margin: 0 auto;
}
.files table th,
.files table td {
  text-align: center;
  padding: 0px 10px;
}
.files table tr {
  border-bottom: solid 1px #eee;
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.ag-body-viewport.ag-layout-normal {
  padding-bottom: 50px;
}

.ag-header-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-cell .MuiFormControl-marginNormal {
  margin: 0 !important;
}

.ag-cell.cell-right-gap {
  border-right: 1px solid #333 !important;
}

.ag-row.row-beneficiary-gp .cell-beneficiary-label,
.ag-row.row-beneficiary-gp .cell-beneficiary-type {
  font-weight: bold;
}

.ag-row.row-beneficiary-child .cell-beneficiary-label {
  padding-left: 28px;
}

.ag-row.row-beneficiary-child .cell-beneficiary-label:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 20px;
  display: block;
  width: 1px;
  height: 20px;
  border-left: 2px dashed #333;
}

.ag-row.row-beneficiary-root-even {
  background: #fff !important;
}

.ag-row.row-beneficiary-root-odd {
  background: #eee !important;
}

.container-pdf .pdf-admin-damageat .visit-value-printable-empty {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  background: #ddd;
}

.container-pdf .signature {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.container-pdf tr:not(:last-child) > th:not(.no-border),
.container-pdf tr:not(:last-child) > td:not(.no-border) {
  border-bottom: 1px solid #000;
}

.container-pdf .obs-result {
  border: 1px solid #000;
  line-height: 1;
}

.container-pdf .obs-result,
.container-pdf .report-result {
  width: 3.2rem;
}

.smart-separator:not(:first-child) {
  border-left: 1px solid #000;
}

@media not print {
  .show-print {
    display: none !important;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  .hidden-print {
    display: none !important;
  }
  .overflow-visible-print {
    overflow: visible !important;
  }
  .content-main {
    padding-top: 0;
  }
  #map {
    width: 100% !important;
    height: 200px !important;
    margin: "auto";
  }
  #centerButton {
    position: absolute;
    right: 1px !important;
  }
}

.form-item {
  margin-top: 30px;
}

.form-item-error {
  color: red;
  margin-top: 0;
  margin-bottom: 3px;
}

.visit-values .MuiChip-root {
  height: auto;
  min-height: 32px;
}

.visit-values-column .MuiChip-root {
  margin-right: 0 !important;
}

.visit-values .MuiChip-label {
  white-space: normal;
  padding-top: 3px;
  padding-bottom: 3px;
}

.MuiDialogContentText-root {
  white-space: pre-line;
}

.bg-light {
  background-color: #ddd !important;
}

/*All styles for letters models*/

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  .mainStyle {
    font-size: 12px;
  }
  .mainStyle .print-footer {
    position: fixed;
    bottom: 0;
  }
  @page {
    size: auto;
    margin: 3mm;
  }
}

.mainStyle {
  margin: 0.5em;
  font-family: "Marianne", "Times New Roman", sans-serif;
  font-weight: 12px;
}

.tableStyle {
  display: flex;
  margin-top: 0.5em;
}

.itemTable {
  font-family: "Marianne", "Times New Roman", sans-serif;
}

.blocStyle {
  width: 55px;
  position: relative;
  left: -1%;
}

.titleLetter {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border: 2px solid #ee7f01;
  padding: 5px;
  font-family: "Marianne", "Times New Roman", sans-serif;
  width: 100%;
}

.tableInstruction {
  text-align: center;
  font-size: 12px;
  border: 1px solid #ee7f01;
}

.itemInstruction {
  text-align: left;
}

.subtitleInstruction {
  text-align: center;
  border: 1px solid #ee7f01;
  padding: 3px;
  font-weight: bold;
}

.tableDamages {
  width: 100%;
  border: 1px solid #ee7f01;
  font-size: 12px;
  margin: auto;
  font-family: "Marianne", "Times New Roman", sans-serif;
}

.signatureStyle {
  margin-right: 1em;
  text-align: right;
}

#myMap {
  position: relative;
}

#centerButton {
  position: absolute;
  top: 1px;
  right: 25%;
  padding: 5px;
  z-index: 1000;
  background-color: white;
  border: solid 1px black;
}

.disabledLetterLink {
  color: #858585;
}

.activeLetterLink {
  color: #3880ff;
}
